@import "bootstrap/scss/mixins";
// Required
@import "variables"; // Required


#thankyou {
    background: $white url("/static/assets/img/ThankYou.svg") no-repeat bottom center;
    background-attachment: fixed;
    background-size: contain;

    h1 {
      font: normal normal 900 46px/40px Montserrat;
      letter-spacing: -3px;
      margin-bottom: 34px;
      color: $purple;
    }

    .sorted {
      margin-top: 5.5rem;
      font: normal normal 600 20px/24px Montserrat;
      letter-spacing: 1px;
    }

    .lead {
      font: normal normal 300 20px/24px Montserrat;
      color: $gray-800;
      max-width:700px;
    }

  }

@include media-breakpoint-up(sm) {
  #thankyou {
    background: $white url("/static/assets/img/ThankYou.svg") no-repeat bottom center;
    background-attachment: fixed;


    h1 {
      font: normal normal 900 87px/86px Montserrat;
      letter-spacing: -3px;
      margin-bottom: 34px;
      color: $purple;
    }

    .sorted {
      margin-top: 7rem;
      font: normal normal 600 20px/24px Montserrat;
      letter-spacing: 1px;
    }

    .lead {
      font: normal normal 300 26px/32px Montserrat;
      color: $gray-800;
    }

  }
}
