@import "bootstrap/scss/mixins";
// Required
@import "variables"; // Required

body {
  *::selection {
    color: #000000;
    background: #f7991e;

  }
}

.vertical-divider {
  height: 50px;
  width: 1px;
  background-color: #A8A8A8;
  margin-left: 0;
  margin-right: 5px;
}

#dashboard-wrapper {
  display: flex;

  .label-success {
    color: #26AA1D;
  }

  .label-good {
    color: #5E7E16;
  }

  .label-warning {
    color: #875E10;
  }

  .label-danger {
    color: #CF2506;
  }

  .progress-success {
    background-color: #26AA1D;
  }

  .progress-good {
    background-color: #5E7E16;
  }

  .progress-warning {
    background-color: #875E10;
  }

  .progress-danger {
    background-color: #CF2506;
  }

  .crawl-reports {
    font: normal normal 500 14px/18px Montserrat;
    margin-right: .5rem;
  }

  #sidebar-wrapper {
    position: relative;
    padding: 20px 30px;

    .logo {
      margin-bottom: 50px;
    }
  }

  #page-content-wrapper {

    #header-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 20px 30px;

      .network {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        margin-right: 30px;
        font: normal normal 500 14px/18px Montserrat;
        letter-spacing: 0px;
        color: #000000;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: $box-shadow-lg;
        border-radius: 6px;
        a {
          color: inherit;
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }

        img {
          width: 22px;
          height: 20px;
        }
      }

      .digital-twin {
        font: normal normal 500 14px/18px Montserrat;
        letter-spacing: 0px;
        color: #000000;

        img {
          width: 20px;
          height: 20px;
        }
      }

      .network-dropdown {
        width: 22rem;

        .dropdown-toggle {
          width: 22rem;
          height: 40px;
          padding: 0px;
          box-shadow: none;

          .dropdown-toggle-content {
            display: flex;
            height: 100%;
            justify-content: space-between;
            align-items: center;
            padding: 0.375rem 0.75rem;
            font: normal normal 500 14px/18px Montserrat;
            letter-spacing: 0px;
            color: #000000;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: $box-shadow-lg;
            border-radius: 6px;
            overflow:hidden;

            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 7px;
              height: 4px;
              color: #76718a;
            }
          }

          &::after {
            display: none !important;
          }
        }

        .dropdown-menu {
          top: -43px !important;
          padding: 0px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border-radius: 6px;
          border: none;
          width: 22rem;

          #dropdownSearchInput {
            width: 22rem;
            height: 40px;
            padding-left: 1.5rem;
            padding-right: 20px;
            margin-bottom: 10px;
            font: normal normal 500 14px/18px Montserrat;
            letter-spacing: 0px;
            color: #000000;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: $box-shadow-lg;
            border-radius: 6px;
            border: none;
            outline: 0;
          }

          .dropdown-items-group {
            height: 137px;
            overflow: scroll;

            .dropdown-item {
              font: normal normal 500 14px/18px Montserrat;
              letter-spacing: 0px;
              color: #000000;

              &:hover {
                color: #f7991e;
                background: #ffffff 0% 0% no-repeat padding-box;
              }
            }

            &::-webkit-scrollbar {
              width: 3px;
              height: 3px;
            }

            &::-webkit-scrollbar-thumb {
              background: #f7991e 0% 0% no-repeat padding-box;
              border-radius: 3px;
              transition: all 0.2s ease-out;
            }

            &::-webkit-scrollbar-track {
              background-color: transparent;
            }
          }
        }
      }

      .user-profile {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 40px;

        .user-dropdown {
          margin-right: 20px;

          .dropdown-toggle {
            width: 58px;
            height: 58px;
            padding: 0;
            box-shadow: 0px 0px 20px #00000029;
            border: 5px solid #ffffff;
            border-radius: 50%;

            &:hover {
              border-color: #f7991e;
            }

            img {
              width: 100%;
              border-radius: 50%;
            }

            &::after {
              display: none !important;
            }
          }

          .dropdown-menu {
            width: 190px;
            height: 275px;
            top: 15px !important;
            left: -62px !important;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: $box-shadow-lg;
            border-radius: 10px;
            border: none;

            .dropdown-item {
              display: flex;
              align-items: center;
              padding-top: 15px;
              padding-bottom: 15px;
              font: normal normal 500 14px/18px Montserrat;
              letter-spacing: 0px;
              color: #000000;

              i {
                color: #76718a;
                font-size: 20px;
              }

              &:hover {
                background: #ffffff 0% 0% no-repeat padding-box;
                color: #f7991e;

                i {
                  color: #f7991e;
                }
              }
            }

            &:before,
            &:after,
            a:first-child:after {
              display: block;
              content: "";
              width: 0;
              height: 0;
              position: absolute;
              left: 86px;
              border: 6px outset transparent;
            }

            &:before {
              border-bottom: 7px solid #fff;
              top: -14px;
            }

            a:first-child:after {
              border-bottom: 7px solid #fff;
              top: -13px;
            }

            &:after {
              border-bottom: 7px solid #fff;
              top: -12px;
            }
          }
        }

        .user-name {
          font: normal normal 500 20px/24px Montserrat;
          letter-spacing: 0px;
          color: #000000;
        }

        .user-role {
          margin-bottom: 0px;
          font: normal normal 500 16px/19px Montserrat;
          letter-spacing: 0px;
          color: #76718a;
        }
      }
    }

    #content-wrapper {
      padding: 20px;

      .section {
        margin-bottom: 50px;

        .section-title {
          font: normal normal 500 18px/22px Montserrat;
          letter-spacing: 0px;
          color: #000000;
          margin-bottom: 20px;

          p {
            font: normal normal normal 16px/26px Montserrat;
            letter-spacing: 0px;
            color: #76718A;
          }

          .btn-primary {
            color: #27111B;
            //font-weight: 400;
          }

          div a {
            font: normal normal 500 14px/22px Montserrat;
            letter-spacing: 0px;
            color: #005ffe;

            .btn-primary {
              color: #27111B;
              //font-weight: 400;
            }
          }


          button {
            padding: 0.575rem 1.25rem;
            font: normal normal 600 14px/18px Montserrat;
            color: #000000;
            background: #f7991e 0% 0% no-repeat padding-box;
            box-shadow: $box-shadow-lg;
            border-radius: 6px;

            &:hover {
              background: #431e2e 0% 0% no-repeat padding-box;
              color: #ffffff;
            }
          }
        }

        .arrow-back-icon {
          img {
            transform: rotate(-180deg);
            margin-top: 6px;
          }

          img.arrow-back-default {
            display: block;
          }

          img.arrow-back-hover {
            display: none;
          }

          &:hover {
            img.arrow-back-default {
              display: none;
            }

            img.arrow-back-hover {
              display: block;
            }
          }
        }
      }
    }
  }

  .tabs {
    .nav-pills {
      margin-bottom: 20px;

      .nav-item {
        margin-right: 15px;

        .nav-link {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 14px 16px;
          color: #000000;
          font: normal normal 600 16px/19px Montserrat;
          border-radius: 8px;

          span.badge-primary {
            background: #e8e8e8;
            color: #929292;
          }

          &:hover:not(.active) {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: $box-shadow-lg;

            span.badge-primary {
              color: #27111B;
              background-color: #F7981E;
            }
          }

          &:active {
            background: #f7991e 0% 0% no-repeat padding-box;
          }

          &.active {
            span.badge-primary {
              color: #27111B;
              background-color: #F7981E;
            }
          }
        }
      }
    }

    table {
      box-shadow: 0px 0px 28px #0000001a !important;
      border-radius: 10px;

      thead tr th,
      tbody tr td {
        font: normal normal 500 14px/18px Montserrat;
        color: black;
      }

      thead {
        background: #f2f2f2 0% 0% no-repeat padding-box;
        min-height: 40px;

        th {
          border: none !important;
          vertical-align: middle !important;
          padding: 15px 25px !important;

          span.column-title {
            //margin-right: 10px;
          }

          span.sorting-arrow {
            cursor: pointer;
            margin-left: .25rem;
          }

          img {
            transform: rotate(-180deg);
            transition: all 0.2s;
          }

          img.sorting-arrow-default {
            display: block;
          }

          img.sorting-arrow-up {
            display: none;
          }

          .sorting-arrow.ascending {
            img.sorting-arrow-default {
              display: none;
            }

            img.sorting-arrow-up {
              display: block;
            }
          }

          .sorting-arrow.descending {
            img {
              transform: rotate(0deg);
            }

            img.sorting-arrow-default {
              display: none;
            }

            img.sorting-arrow-up {
              display: block;
            }
          }

          //&:first-child {
          //  width: 50%;
          //}
        }
      }

      tbody {
        tr {
          td {
            padding: .625rem;
            vertical-align: middle;

            .btn-request {
              //width: 135px;
              height: 40px;
              color: #431e2e;
              font: normal normal 600 14px/25px Montserrat;
              background: #d9d2d5 0% 0% no-repeat padding-box;
              box-shadow: $box-shadow-lg;
              border-radius: 6px;

              &:hover {
                color: #ffffff;
                fill: #ffffff;
                background: #431e2e 0% 0% no-repeat padding-box;
              }
            }

            //.severity {
            //  img {
            //    position: absolute;
            //    z-index: 1;
            //  }
            //}
            //&:first-child {
            //  width: 50%;
            //}
            //&:first-child:hover {
            //  color: #005ffe;
            //  cursor: pointer;
            //}

            &:last-child {
              text-align: right;
            }
          }
        }
      }
    }
  }

  .custom-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: normal normal 500 14px/18px Montserrat;

    .pagination {
      margin-bottom: 0px;

      .page-item {
        .page-link {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          padding: 0;
          color: #000000;
          margin-left: 5px;
          border-radius: 6px;
          border: none;

          &:focus {
            box-shadow: none;
          }
        }

        &:hover {
          .page-link {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: $box-shadow-lg;
          }
        }

        &:active,
        &.active {
          .page-link {
            color: #ffffff;
            background: #000000 0% 0% no-repeat padding-box;
          }
        }

        &:first-child,
        &:last-child {
          .page-link {
            width: auto;
            height: auto;
            padding: 0.5rem 0.75rem;
          }
        }

        &:first-child:hover {
          .page-link {
            color: #f7991e;
            background: transparent;
            box-shadow: none;
          }
        }

        &:first-child:active,
        &:first-child.active {
          .page-link {
            color: #f7991e;
            background: transparent;
            box-shadow: none;
          }
        }

        &:last-child:hover {
          .page-link {
            color: #f7991e;
            background: transparent;
            box-shadow: none;
          }
        }

        &:last-child:active,
        &:last-child.active {
          .page-link {
            color: #f7991e;
            background: transparent;
            box-shadow: none;
          }
        }
      }

      .page-number {
        color: #000000;
      }
    }

    .items-number {
      display: flex;
      align-items: center;
      color: #000000;

      .dropdown-toggle {
        font: normal normal 600 14px/18px Montserrat;
        box-shadow: none;

        &::after {
          display: none !important;
        }

        &:hover {
          color: #f7991e;
        }
      }

      .dropdown-menu {
        top: 24px !important;
        width: 51px;
        min-width: 51px !important;
        border: none;

        .dropdown-item {
          padding-left: 0px;
          padding-right: 0px;
          text-align: center;
          font: normal normal 500 14px/18px Montserrat;
          letter-spacing: 0px;
          color: #000000;

          &:hover {
            color: #f7991e;
            background: #ffffff 0% 0% no-repeat padding-box;
          }
        }
      }
    }
  }

  .dashboard-cards #micro-tabs {
    .card {
      height: 300px;
      width: 100%;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: $box-shadow-lg;
      border-radius: 10px;
      border: none;
      padding:0;

      #search{
        padding:0 20px;
        border-bottom: 1px solid rgba(5,20,40,0.125);

        .search-box {
          border-left: 1px solid rgba(5,20,40,0.125);
          padding: 6px 0 6px 20px;
        }
        #search-urls {
          border: none;
          box-shadow: none;
          background-image: url(/static/assets/img/sidemenu-seo-activeIcon.svg);
          background-repeat: no-repeat;
          background-position: 95% 50%;
        }
      }

      a.badge {
        color: $black;
      }


      .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff 0% 0% no-repeat padding-box;
        padding-top: 20px;
        padding-bottom: 20px;

        h3 {
          font: normal normal 500 18px/22px Montserrat;
          color: #000000;
          margin-bottom: 0px;
        }

        .sortby-section {
          display: flex;
          align-items: center;
          font: normal normal 600 12px/15px Montserrat;

          .sortby-title {
            color: #000000;
            font-weight: 600;
            margin-right: 10px;
          }

          .sortby-content {
            display: flex;
            align-items: center;
          }
        }
      }

      .list-group {
        overflow: scroll;

        .list-group-item {
          display: grid;
          //grid-template-columns: 85% 15%;
          font: normal normal 500 12px/15px Montserrat;
          color: #000000;

          .page-url {
            //width: 100%;
          }

          .page-title {
            min-width: 120px;
          }

          .website-link {
            color: #005ffe;
          }
        }

        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background: #f7991e 0% 0% no-repeat padding-box;
          border-radius: 3px;

          transition: all 0.2s ease-out;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
      }
    }

    .divider {
      height: 15px;
      width: 2px;
      background-color: #76718a;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .screenshot-container {
    height: 290px;
    position: relative;

    .close-icon {
      position: absolute;
      display: none;
      top: 12px;
      right: -204px;
      margin-right: 10px;
      z-index: 2;
      cursor: pointer;

      .hover-icon {
        display: none;
      }

      &:hover {
        .default-icon {
          display: none;
        }

        .hover-icon {
          display: block;
        }
      }
    }

    .screenshot {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 262px;
      height: 258px;
      text-align: center;
      border: 1px solid #dedede;
      border-radius: 10px;
      z-index: 1;
      overflow: hidden;
      transition: all 0.3s;

      img.screenshot-big {
        //width: 224px;
        //height: 133px;
        transition: all 0.3s;

        position: absolute;
        top: 1rem;
        border: 2px solid white;
        &.screenshot_no_banner {
          clip: rect(0, 138px, 171px, 0);
          width: 133px;
        }
        &.final_screenshot {
          height: 133px;
        }
        &.full_page_screenshot {
          clip: rect(0, 138px, 171px, 0);
          width: 133px;
        }
      }

      .screenshot-detail {
        margin-top: 15px;
        position: absolute;
        bottom: 0;

        span {
          font: normal normal 500 16px/19px Montserrat;
          letter-spacing: 0px;
          color: #000000;
        }

        p {
          font: normal normal 500 12px/15px Montserrat;
          letter-spacing: 0px;
          color: #76718a;
        }
      }

      &:hover {
        cursor: pointer;
        border: 1px solid #f7991e;
        box-shadow: $box-shadow-lg;
      }

      &:active,
      &.active {
        width: 473px;
        height: 379px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: $box-shadow-lg;
        border: none;
        overflow: hidden;

        img.screenshot-big {
          box-shadow: $box-shadow-lg;
          &.screenshot_no_banner {
            clip: rect(0, 240px, 270px, 0);
            width: 240px;
          }
          &.final_screenshot {
            height: 258px;
          }
          &.full_page_screenshot {
            clip: rect(0, 240px, 270px, 0);
            width: 240px;
          }

        }
      }
    }
  }

  .side-menu {
    width: 265px;

    .menu-content {

      .menu-item {
        margin-bottom: 0.65rem;
        cursor: pointer;

        .item-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 45px;
          padding: 0px 20px;

          a {
            display: flex;
            width: 100%;
            padding: 0.75rem 0;
          }

          .title, a {
            font: normal normal 500 16px/19px Montserrat;
            color: #000000;
          }

          img {
            width: 12px;
            height: 7px;

            &.default-icon {
              width: 17px;
              height: 25px;
              margin-right: 12px;
              display: block;
            }

            &.active-icon {
              width: 17px;
              height: 25px;
              margin-right: 12px;
              display: none;
            }

            &.arrow-down-default {
              display: block;
            }

            &.arrow-down-active {
              display: none;
            }
          }
        }

        .item-content {
          width: 100%;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: $box-shadow-lg;

          .submenu {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            padding: 6px;

            li {
              &.list-group-item {
                padding: 0;
              }

              a {
                padding: .75rem 1.25rem;
                width: 100%;
              }

              display: flex;
              align-items: center;
              height: 45px;
              border: none;

              img {
                width: 16px;
                height: 19px;

                &.default-icon {
                  display: block;
                }

                &.active-icon {
                  display: none;
                }
              }

              a {
                font: normal normal 500 14px/18px Montserrat;
                letter-spacing: 0px;
                color: #000000;
                display: flex;
                text-decoration: none;

                span {
                  margin-left: 16px;
                }
              }

              &:hover {
                background: #f4f4f4 0% 0% no-repeat padding-box;
                border-radius: 6px;

                img.default-icon {
                  display: none;
                }

                img.active-icon {
                  display: block;
                }
              }
            }
          }
        }

        &:hover,
        &:active,
        &.active {
          .item-header {
            background: #f7991e 0% 0% no-repeat padding-box;
            box-shadow: $box-shadow-lg;
            border-radius: 6px;

            img.arrow-down-default {
              display: none;
            }

            img.arrow-down-active {
              display: block;
            }

            img.default-icon {
              display: none;
            }

            img.active-icon {
              display: block;
            }
          }
        }

        &:active,
        &.active {
          .item-header {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          }

          .item-header.collapsed {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
      }
    }

    .divider {
      width: 100%;
      background-color: #dedede;
      border: 1px solid #dedede;
      margin: 40px 0px;
    }

    .network-issues {
      padding-left: 15px;

      &__item {
        display: flex;
        height: 25px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;

        .issue-title {
          font: normal normal 500 16px/19px Montserrat;
          color: #000000;

          a {
            color: $black;
          }

          img {
            margin-right: 15px;
          }
        }

        .issue-percentage {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 70px;
          height: 25px;
          font: normal normal 500 12px/15px Montserrat;
          color: #000000;
          border: 1px solid #76718a;
          border-radius: 13px;
        }

        #total {
          color: #ffffff;
          background: #76718a 0% 0% no-repeat padding-box;
          border: 1px solid #76718a;
        }
      }
    }
  }

  .dashboard-cards {
    display: flex;

    [class*="col-"] {
      padding-right: 5px;
      padding-left: 5px;
    }

    .card {
      width: 100%;
      height: 192px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: $box-shadow-lg;
      border-radius: 10px;
      border: none;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 16px;

      .card-header {
        display: flex;
        justify-content: space-between;
        padding: 0px;
        background-color: white;
        border: none;

        .card-title {
          font: normal normal 600 18px/22px Montserrat;
          letter-spacing: 0px;
          color: #000000;
          margin-bottom:0;
        }

        .card-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          border-radius: 50%;

          img {
            width: 17px;
            height: 17px;
          }
        }
      }

      .card-body {
        padding: 0px;

        h1,
        h6 {
          margin-bottom: 0px;
        }

        h1 {
          font: normal normal 700 41px/50px Montserrat;
          color: #000000;
        }

        h6 {
          font: normal normal 600 14px/15px Montserrat;
          color: #76718a;
        }

        small {
          font: normal normal normal 12px/15px Montserrat;
          color: #76718a;
        }

        .issues,
        .industry-percentage {
          p {
            line-height: 2px;
          }
        }

        .progress {
          height: 3px;
        }

        .growth {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          margin-top: 8px;

          canvas {
            width: 100%;
          }

          .growth-percentage {
            display: flex;
            justify-content: center;
            align-items: center;
            //width: 56px;
            height: 23px;
            background: #dedede 0% 0% no-repeat padding-box;
            border-radius: 4px;

            img {
              width: 7px;
              height: 8px;
              margin-right: 7px;
            }
          }
        }

        .divider {
          width: 100%;
          border: 1px solid #dedede;
          background-color: #dedede;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .score-status {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          h6 {
            font: normal normal bold 16px/22px Montserrat;
            letter-spacing: 0px;
            color: #000000;
            margin-bottom: 0;
            margin-right: 5px;
          }

          span {
            font: normal normal 600 16px/22px Montserrat;
            letter-spacing: 0px;
            color: #5e7e16;
          }
        }

        .description {
          .card-text,
          span {
            &::-moz-selection {
              color: #000000;
              background: #f7991e;
            }

            &::selection {
              color: #000000;
              background: #f7991e;
            }
          }
        }
      }

      .card-text {
        font: normal normal normal 16px/24px Montserrat;
        letter-spacing: 0px;
        color: #76718a;
      }
    }

    .overallscore-card {
      height: 404px;
      border: 2px solid #5E7E16;

      .card-icon {
        background: #dae2ca 0% 0% no-repeat padding-box;
      }
    }

    .seo-card {
      .card-icon {
        background: #E2F4FB 0% 0% no-repeat padding-box;
      }
    }

    .performance-card {
      .card-icon {
        background: #E9DDF6 0% 0% no-repeat padding-box;
      }
    }

    .accessibility-card {
      .card-icon {
        background: #D9F1E6 0% 0% no-repeat padding-box;
      }
    }

    .privacy-card {
      .card-icon {
        background: #fa3cbe4d 0% 0% no-repeat padding-box;
      }
    }

    .content-card {
      .card-icon {
        background: #f8efdf 0% 0% no-repeat padding-box;
      }
    }

    .best_practices-card, .code-card {
      .card-icon {
        background: #c5dbff 0% 0% no-repeat padding-box;
      }
    }
  }

  .policy-issue-item {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: $box-shadow-lg;
    border-radius: 10px;

    .policy-issue-item-container {
      position: relative;
    }

    .item-header {
      padding: 20px;

      .policy-title {
        a {
          display: flex;
          align-items: center;
          font: normal normal 700 18px/22px Montserrat;
          letter-spacing: 0px;
          color: #000000;
          cursor: pointer;
        }

        .arrow-down-default {
          display: block;
          transform: rotate(0deg);
          transition: all 0.2s;
        }

        .arrow-down-hover {
          display: none;
          transform: rotate(0deg);
          transition: all 0.2s;
        }

        &:hover {
          a {
            color: #005ffe;
          }

          .arrow-down-default {
            display: none;
          }

          .arrow-down-hover {
            display: block;
          }
        }
      }

      .categories {
        margin-bottom: 30px;
        font: normal normal 500 14px/22px Montserrat;
        color: #76718a;

        span {
          font-weight: 700;
          letter-spacing: 0px;
          color: #000000;
        }
      }

      //.severity {
      //  span {
      //    font: normal normal 500 14px/20px Montserrat;
      //    letter-spacing: 0px;
      //    color: #000000;
      //  }
      //  .severity-status {
      //    margin-left: 18px;
      //    margin-right: 30px;
      //    img {
      //      position: absolute;
      //      z-index: 1;
      //    }
      //  }
      //  .affected-pages {
      //    width: 143px;
      //    height: 23px;
      //    font: normal normal 500 14px/22px Montserrat;
      //    letter-spacing: 0px;
      //    background: #dedede 0% 0% no-repeat padding-box;
      //    border-radius: 4px;
      //  }
      //}
    }

    .submit-request {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 301px;
      height: 100%;
      font: normal normal 700 13px/20px Montserrat;
      letter-spacing: 0px;
      color: #000000;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-left: 1px solid #e6e5e6;
      border-radius: 0px 10px 10px 0px;
      transition: background 0.2s;
      text-decoration: none;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 66px;
        height: 66px;
        background: #d4cccf 0% 0% no-repeat padding-box;
        border-radius: 50%;
      }

      p {
        width: 152px;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 20px;
        margin-bottom: 0px;
        transition: color 0.2s;
      }

      &:hover {
        background: #431e2e 0% 0% no-repeat padding-box;

        p {
          color: #ffffff;
        }
      }
    }

    .item-content {
      .list-group {
        .list-group-item {
          display: grid;
          //grid-template-columns: 30% 70%;
          align-items: center;
          border-left: 0px;
          border-right: 0px;
          font: normal normal 500 14px/18px Montserrat;
          letter-spacing: 0px;
          color: #000000;

          &:first-child {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
          }

          &:last-child {
            border-bottom: 0px;
          }

          .issue-items {
            a {
              color: #000000;
              text-decoration: underline;
            }
          }

          .counter {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 41px;
            height: 24px;
            font: normal normal 500 14px/22px Montserrat;
            letter-spacing: 0px;
            color: #76718a;
            border: 1px solid #76718a;
            border-radius: 12px;
          }

          .divider {
            height: 18px;
            width: 2px;
            background-color: #dedede;
            margin-left: 8px;
            margin-right: 8px;
          }
        }
      }
    }

    &.expanded {
      .item-header {
        .arrow-down-default {
          transform: rotate(-180deg);
        }

        .arrow-down-hover {
          transform: rotate(-180deg);
        }
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  #dashboard-wrapper {
    //.severity-success,
    //.severity-warning,
    //.severity-danger {
    //  font: normal normal 500 12px/18px Montserrat;
    //}
    #page-content-wrapper {
      .header-wrapper, #header-wrapper {
        .network {
          font: normal normal 500 12px/18px Montserrat;

          img {
            width: 20px;
            height: 18px;
          }
        }

        .digital-twin, .current-website {
          font: normal normal 500 12px/18px Montserrat;
        }

        .divider {
          height: 45px;
        }

        .network-dropdown {
          .dropdown-toggle {
            .dropdown-toggle-content {
              font: normal normal 500 12px/18px Montserrat;
            }
          }

          .dropdown-menu {
            #dropdownSearchInput {
              font: normal normal 500 12px/18px Montserrat;
            }

            .dropdown-items-group {
              .dropdown-item {
                font: normal normal 500 12px/18px Montserrat;
              }
            }
          }
        }

        .user-profile {
          .user-dropdown {
            .dropdown-toggle {
              width: 58px;
              height: 58px;
            }

            .dropdown-menu {
              .dropdown-item {
                font: normal normal 500 12px/18px Montserrat;
              }
            }
          }

          .user-name {
            font: normal normal 500 18px/24px Montserrat;
          }

          .user-role {
            font: normal normal 500 14px/19px Montserrat;
          }
        }
      }

      #content-wrapper {
        .section {
          .section-title {
            font-size: 16px;

            a {
              font-size: 13px;
            }

            button {
              font-size: 12px;
            }
          }
        }
      }
    }

    .screenshot-container {
      .screenshot {
        width: 230px;

        img.screenshot-big {
          width: 185px;
        }

        &:active,
        &.active {
          width: 370px;

          img.screenshot-big {
            width: 330px;
            height: 230px;
          }
        }
      }

      .close-icon {
        right: -137px;
      }
    }

    .side-menu {
      width: 230px;

      .menu-content {
        .menu-item {
          .item-header {
            .title {
              font-size: 14px;
            }
          }

          .item-content {
            .submenu li a {
              font-size: 12px;
              margin-left: 0;
              display: flex;
              padding: .75rem 1.25rem;


              span {
                margin-left: 16px;
              }
            }
          }
        }
      }

      .network-issues__item {
        .issue-title {
          font-size: 14px;
        }

        #total {
          font-size: 12px;
        }
      }
    }

    .dashboard-cards {
      .card {
        .card-header {
          .card-title {
            font-size: 16px;
          }

          .card-icon {
            width: 35px;
            height: 35px;

            img {
              width: 15px;
              height: 15px;
            }
          }
        }

        .card-body {
          h1 {
            font-size: 32px;
          }

          h6 {
            font-size: 12px;
          }

          .score-status {
            h6 {
              font-size: 13px;
            }

            span {
              font-size: 14px;
            }
          }

          small {
            font: normal normal normal 10px/15px Montserrat;
          }
        }

        .card-text {
          font: normal normal normal 14px/22px Montserrat;
        }
      }
    }

    .dashboard-cards #micro-tabs {
      .card {
        .card-header {
          h3 {
            font-size: 16px;
          }

          .sortby-section {
            font-size: 10px;
          }
        }

        .list-group {
          .list-group-item {
            font-size: 10px;
          }
        }
      }
    }

    .policy-issue-item {
      .item-header {
        .policy-title {
          a {
            font-size: 16px;
          }
        }

        .categories {
          font-size: 12px;
        }

        .severity {
          //span {
          //  font: normal normal 500 14px/20px Montserrat;
          //}
          //.severity-status {
          //  margin-left: 18px;
          //  margin-right: 30px;
          //  img {
          //    position: absolute;
          //    z-index: 1;
          //  }
          //}
          //.affected-pages {
          //  font-size: 12px;
          //}
        }
      }

      .submit-request {
        width: 270px;
        height: 100%;
        font-size: 12px;

        .icon {
          width: 60px;
          height: 60px;
        }
      }

      .item-content {
        .list-group {
          .list-group-item {
            font-size: 11px;

            .counter {
              width: 37px;
              height: 20px;
              font-size: 11px;
            }
          }
        }
      }
    }

    .tabs {
      .nav-pills {
        .nav-item {
          .nav-link {
            font-size: 14px;
          }
        }
      }

      table {
        thead tr th,
        tbody tr td {
          font: normal normal 500 12px/18px Montserrat;
        }

        thead {
          th {
            &:first-child {
              //width: 35%;
            }
          }
        }

        tbody {
          tr {
            td {
              .btn-request {
                font-size: 12px;
              }

              &:first-child {
                //width: 35%;
              }
            }
          }
        }
      }
    }

    .custom-pagination {
      font: normal normal 500 12px/18px Montserrat;

      .items-number {
        .dropdown-toggle {
          font: normal normal 600 12px/18px Montserrat;
        }

        .dropdown-menu {
          .dropdown-item {
            font: normal normal 500 12px/18px Montserrat;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 1500px) {
  #dashboard-wrapper {
    .dashboard-cards {
      .card {
        .card-text {
          font: normal normal normal 13px/19px Montserrat;
        }
      }
    }

    .policy-issue-item {
      .item-content {
        .list-group {
          .list-group-item {
            //grid-template-columns: 25% 75%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  #dashboard-wrapper {
    //.severity-success,
    //.severity-warning,
    //.severity-danger {
    //  width: 100px;
    //  height: 20px;
    //  font: normal normal 500 10px/18px Montserrat;
    //}
    //.severity-icon {
    //  width: 20px;
    //  height: 20px;
    //}
    #page-content-wrapper {
      #content-wrapper {
        .section {
          .section-title {
            font-size: 15px;
          }
        }
      }
    }

    .screenshot-container {
      .screenshot {
        width: 200px;
        height: 290px;

        img.screenshot-big {
          width: 160px;
          height: 100px;
        }

        &:active,
        &.active {
          width: 339px;
          height: 330px;

          img.screenshot-big {
            width: 300px;
            height: 200px;
          }
        }

        .screenshot-detail {
          span {
            font-size: 14px;
          }

          p {
            font-size: 10px;
          }
        }
      }
    }

    .side-menu {
      width: 200px;
      //margin-top: 280px;

      .menu-content {
        .menu-item {
          margin-bottom: 0.65rem;
          cursor: pointer;

          .item-header {
            img {
              &.default-icon {
                width: 15px;
              }

              &.active-icon {
                width: 15px;
              }
            }
          }

          .item-content {
            .submenu {
              li {
                padding-left: 10px;
                padding-right: 10px;

                img {
                  width: 15px;
                }
              }
            }
          }
        }
      }

      .network-issues__item {
        .issue-title {
          font-size: 14px;
        }

        #total {
          font-size: 12px;
        }
      }
    }

    .dashboard-cards {
      .card {
        .card-header {
          .card-title {
            font-size: 13px;
          }

          .card-icon {
            width: 30px;
            height: 30px;

            img {
              width: 13px;
              height: 13px;
            }
          }
        }

        .card-body {
          h1 {
            font-size: 30px;
          }

          h6 {
            font-size: 10px;
          }

          .score-status {
            h6 {
              font-size: 11px;
            }

            span {
              font-size: 12px;
            }
          }

          small {
            font: normal normal normal 10px/15px Montserrat;
          }
        }

        .card-text {
          font: normal normal normal 11px/19px Montserrat;
        }
      }
    }

    .dashboard-cards #micro-tabs {
      .card {
        .card-header {
          h3 {
            font-size: 15px;
          }
        }
      }
    }

    .policy-issue-item {
      .item-header {
        .policy-title {
          a {
            font-size: 15px;
          }
        }

        .categories {
          font-size: 12px;
        }

        .severity {
          //span {
          //  font: normal normal 500 13px/20px Montserrat;
          //}
        }
      }
    }

    .tabs {
      .nav-pills {
        .nav-item {
          .nav-link {
            font-size: 13px;
          }
        }
      }

      table {
        thead tr th {
          white-space: nowrap;
        }
      }
    }
  }
}
