@import "bootstrap/scss/mixins";
@import "variables"; // Required

//@keyframes fadeOutUp {
//  from {
//    opacity: 1;
//    top: 0
//  }
//  to {
//    opacity: 0;
//    top: -150px;
//    transform: translate3d(0, -100%, 0);
//  }
//}

#cookie-consent {
  padding: 1rem;

  &.hide {
    display:none
  }

  .auto-align-middle{
    margin-top: auto;
    margin-bottom: auto;
  }
  h1, p, a.btn {
    font: normal normal 16px/19px Montserrat;
  }
  h1 {
    font-weight: 600;
  }
  .btn {
    border-radius: 8px;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    padding:.95rem 1.8rem;
  }
}
