@import "bootstrap/scss/mixins";
// Required
@import "variables"; // Required


@-webkit-keyframes animateThis {
  0% {
    width: 10%;
  }
  100% {
    width: 95%;
  }
}


#loading {
  z-index: 999999;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(190, 190, 190, .9);


  .progress {
    width: 50%;

    &-bar.start {
      animate: animateThis 20s ease, progress-bar-stripes 1s linear 20s;
      -webkit-animation: animateThis 20s ease, progress-bar-stripes 1s linear 20s;
    }
  }
}
