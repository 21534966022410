@import "bootstrap/scss/mixins";
// Required
@import "variables"; // Required


body.page-audit {
  .card-header {
    font: normal normal 400 14px/18px Montserrat;
    border-radius: calc(.5rem - 1px) calc(.5rem - 1px);

    .issues-on-page {
      border-right: 1px solid #DEDEDE;
      margin-right: 1rem;
      padding-right: 1rem;
      padding-top: .75rem;
      padding-bottom: .75rem;
    }
    .severity-label{
      padding-right:.75rem;
    }

    .detail-row{
      box-shadow: $box-shadow-lg;
    }

    span.issue-description {
      font: normal normal 500 14px/18px Montserrat;
    }

    h2 {
      font: normal normal 600 16px/20px Montserrat;
    }
  }
}

.detail {
  &-title {
    margin: 0.75rem 1.25rem;
    font: normal normal bold .875rem/1.125rem Montserrat;
  }

  &-box {
    margin: 0.75rem 1.25rem;
    border-radius: 0.25rem;
    font: normal normal normal .875rem/1.125rem Montserrat;
    &.preview {
      max-height:200px;
      max-width: 500px;
      overflow:hidden;
      & img {
        max-height:200px;
        max-width: 500px;
      }
    }
    &.box-code {
      border: 1px solid #D1D1D1;
      font: normal normal bold .875rem/1.25rem Montserrat;
      padding: 8px;
    }
  }
}

.accordion {
  &-details {
    margin-top: .75rem;
  }

  .row .col {
    padding: 1rem;

  }

  .card {
    margin-bottom: 1.25rem;

    &-header {
      background-color: #F2F2F2;
    }
  }

  img.arrow-down-default {
    display: inline-block;
  }

  img.arrow-down-hover {
    display: none
  }

  .severity {
    span {
      padding-left: 50px;
      font: normal normal normal .875rem/1.125rem Montserrat
    }
  }

  &.expanded {
    img.arrow-down-default {
      transform: rotate(-180deg);
      display: none
    }

    img.arrow-down-hover {
      transform: rotate(-180deg);
      display: inline-block;
    }
  }

  .code-snippet {
    margin-left: 0.5rem;
    padding-left: .5rem;
    border-left: 1px solid $gray-300;
  }
}
