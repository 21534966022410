@import "bootstrap/scss/mixins";
// Required
@import "variables"; // Required

.debug {
  .screenshot {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent url('/static/assets/img/screenshot.png') no-repeat center top;
    opacity: 0.2;
    min-height: 8000px;
    z-index: 999;
  }
}

.navbar {
  background-color: $primary;
}
#landingpage {
  background-color: $white;
  .cc-revoke {
    display:none;
  }


  //.sticky_top {
  //  position: -webkit-sticky; /* Safari */
  //  position: sticky;
  //  top: 0;
  //  padding-top: 0rem;
  //}

  .sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    padding-top: 3rem;
    margin-top:-3rem;
  }

  .navbar {
    background-color: $primary;
    padding: .5rem 1rem 2rem 1rem;

    &-header {
      width: 100%;
      text-align: center;
    }
  }

  section {
    z-index: 0;
    position: relative;
    background-color: transparent;
    padding: 0 0 4rem 0;

    .h3, h3 {
      &.badge {
        font-size: 80%;
        font-weight: 600;
        background-color: rgba($primary, .4);
        color: $black;
      }
    }

    h2 {
      font: normal normal 500 30px/38px Montserrat;
      letter-spacing: -1px;
      word-spacing: -2px;
    }

    p {
      color: $gray-700;
      font: normal normal 16px/26px Poppins;

      &.lead {
        color: $gray-900;
        font-weight: 500;
      }
    }

    .card-body {
      ul {
        list-style: none; /* Remove default bullets */
        padding-left: 23px;

        li {
          //font-size: 1px;
        }
      }

      ul li::before {
        content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: $primary; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        font-size: 24px;
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: .75em; /* Also needed for space (tweak if needed) */
        margin-left: -.75em; /* Also needed for space (tweak if needed) */
      }
    }
  }

  .jumbotron-fluid {
    background: $primary url('/static/assets/img/cutout.svg') no-repeat bottom center;
    background-size: contain;
    text-align: center;
    height: 500px;

    .container {
      background: transparent url('/static/assets/img/Wasp-Swoosh-Clouds.svg') no-repeat  -50px 181px;
      background-size: 90%;
      height: 100%;
    }

  }

  #headlines {
    .h3 {
      color: $purple;
      font-size: 18px;
    }

    h1 {
      font: normal normal 900 37px/42px Montserrat;
      letter-spacing: -1px;

      span {
        color: $purple;
      }
    }

    #get-in-the-beta {
      color: $gray-700;
    }
  }


  form {
    margin: 1em 0;
    .smallprint {
      margin-top: 1rem;
    }
    .form-check {
      padding-left: 0;
      padding-top:0.25rem;
      [type=checkbox], [type=checkbox]:before, [type=checkbox]:after{
        box-sizing: content-box;
      }
      label {
        font-size:1rem;
        color: $white;
      }

      a {
        color: $secondary;
        text-decoration: underline;
      }
    }

    input {
      box-shadow: $box-shadow-lg;

    }

    .btn {
      box-shadow: $box-shadow-lg;
    }
  }

  p {
    font-family: Poppins, $font-family-base;
  }

  .btn {
    background-image: none;

    &:hover {
      background-image: none;
    }
  }

  .btn-lg.login {
    font-weight: 600;
    padding: 0.5rem 2rem;
    font-size: 16px;
    border-radius: 0.4rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 28px rgba(0, 0, 0, .29);
    color: $black;
  }


  #sign-up {
    padding-bottom: 0rem;
  }

  #greyshaped {
    background: transparent url(/static/assets/img/GreyShape1.svg) no-repeat 50% 0%;
    margin-top: 3rem;
    background-size: 400%;
  }




  #my_website {
    //background: transparent; //url("/static/assets/img/Main-Screen.png") no-repeat 140% 100%;
    overflow: hidden;

    img.dashboard {
      height: 400px;
    }
  }

  #suite-audits {
    padding-bottom: 3rem;
    .card {
      min-height: 315px;
      margin-bottom:1rem;

      p.more {
        position: absolute;
        bottom: 10px;
      }
    }
  }

  #metrics {
    padding-bottom: 0rem;
  }

  #policies {
    background: #fff url(/static/assets/img/GreyShape2.svg) no-repeat 15% 50%;
    padding-top: 4rem;

  }

  #crawl_analyze_report {
    padding-bottom: 1rem;

    .card {
      box-shadow: $box-shadow-lg;
      margin-top: 2rem;

      & + .card {
        margin-top: 4rem;
      }

      &-body {
        padding: 4rem 2rem;

        ul {
          list-style: none; /* Remove default bullets */
          li {
            font-size: 16px;
            line-height:26px;
          }
        }

        ul li::before {
          content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
          color: $primary; /* Change the color */
          font-weight: bold; /* If you want it to be bold */
          font-size: 24px;
          display: inline-block; /* Needed to add space between the bullet and the text */
          width: 1em; /* Also needed for space (tweak if needed) */
          margin-left: -1em; /* Also needed for space (tweak if needed) */
        }
      }

      .h3, h3 {
        position: absolute;
        top: -46px;
        font-size: 19px;
        padding: 1em 0em 1em 0em;

        span {
          background: $primary;
          position: relative;
          margin-left: -2rem;
          border-radius: 5px;
          padding: .4rem .4rem .4rem 2rem;
          font-weight: 600;
          box-shadow: $box-shadow-lg;
        }

        img {
          position: relative;
          background: $white;
          height: 60px;
          //padding: 1.5rem;
          z-index: 999;
          box-shadow: $box-shadow-lg;
        }
      }
    }
  }

  #compliance {
    text-align: left;

    .form-check {
      //padding: 0.5rem 1.4rem;
      label {
        color: $body-color;
      }

      a {
        //color: $primary;
      }
    }
  }

  #policies, #compliance {
    padding-bottom: 4rem;
  }

  #beta {
    background: #fff url(/static/assets/img/Group-558.svg) no-repeat 20% 50%;
    padding: 4rem 0;
    margin: 2rem 0 3rem 0;
    background-size: cover;

    .badge {
      background: $primary;
    }

    h2 {
      color: $white;
      margin-bottom: 2.4rem;
    }

    .form-check {
      a {
        color: $primary;
      }
    }
  }

}



footer {
  background: $secondary url('/static/assets/img/fig.svg') no-repeat top right;
  background-size: contain;
  padding: 1.5rem 0;
  p {
    font-size:14px;
    margin-bottom:0;
  }
  .list-inline-item:not(:last-child) {
    margin-right: 1rem;
  }
  ul > li {
    font-size:14px;
    padding: 1rem 0 1rem 0;

    & > a {
      font-size:14px;
      color: $white;
      font-weight: 600;

      &:hover {
        color: $white;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  footer {
    ul > li:first-child {
      padding: 0 0 1rem 0;
    }
  }
  #landingpage{
    #suite-audits {
      .card {
        min-height: 460px;
      }
    }
    #my_website {
      //background: transparent url(/static/assets/img/Main-Screen.png) no-repeat 140% 50%;
      //background-size: 67%;
    }
  }

}
@include media-breakpoint-up(md) {
  #landingpage {
    //.cc-revoke {
    //  display:flex;
    //}
    #compliance {
      p.lead {
        max-width: 48%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .navbar {
      &-header {
        width: auto;
        text-align: left;
      }
    }

    .jumbotron-fluid {
      padding-top: 22px;
      text-align: left;
      height: auto;

      .form-check {
        color: $white a {
          color: $secondary;
          background-color: $primary;
        }
      }

      .container {
        background: transparent url(/static/assets/img/Wasp-Swoosh-Clouds.svg) no-repeat 100% 100px;
        background-size: contain;
        height: auto;
      }
    }
        #headlines {
          padding: 3rem 0 6rem 0;

          .h3 {
            //font-size: 26px;
            font-weight: 500;
            //margin-bottom: 20px;
          }

          h1 {
            //font: normal normal 900 87px/86px Montserrat;
            //letter-spacing: -3px;
            //margin-bottom: 34px;
            span {
              color: $purple;
            }
          }

          p {
            //font: normal normal 300 24px/34px Poppins;
            letter-spacing: 0px;
          }
          #get-in-the-beta {
            color: $white;
          }


        }
  }
  section {
    padding-bottom: 1rem;
  }
}
@include media-breakpoint-up(lg) {
  #landingpage {
    #my_website{
      background: transparent url(/static/assets/img/Main-Screen.png) no-repeat 140% 50%;
      background-size: 65%;
      padding:0 0 5rem 0
    }
  }
}


@include media-breakpoint-up(xl) {
  #landingpage {
    .navbar {
      &-header {
        width: auto;
        text-align: left;
      }
    }

    form {
      margin: 2.6em 0;
      max-width: 78%;
      .form-check {
        padding: 1.25rem 0;
        padding-left:0;
      }
      input {
        padding: 1.5em 1em;
        //font-size: 20px;
        font-weight: 500;
        border-radius: 0.6rem 0 0 0.6rem;
      }
    }

    .btn {
      font-size: 20px;
      padding-left: 2.8rem;
      padding-right: 2.8rem;
      font-weight: 600;
    }
    .btn.btn-lg.login{
      padding: 0.5rem 2rem;
      font-size: 16px;
    }



    .jumbotron-fluid {
      padding-top: 66px;
      text-align: left;
      height:auto;
      .form-check {
        color: $white
        a {
          color: $secondary;
          background-color: $primary;
        }
      }
      .container {
        background: transparent url(/static/assets/img/Wasp-Swoosh-Clouds.svg) no-repeat 100% 100px;
        background-size: contain;
        height:auto;
      }

    }

    #headlines {
      padding: 3rem 0 12rem 0;

      .h3 {
        font-size: 26px;
        font-weight: 500;
        margin-bottom: 20px;
      }

      h1 {
        font: normal normal 900 87px/86px Montserrat;
        letter-spacing: -3px;
        margin-bottom: 34px;
        span {
          color: $purple;
        }
      }

      p {
        font: normal normal 300 24px/34px Poppins;
        letter-spacing: 1px;
      }
      #get-in-the-beta {
        color: $white;
      }


    }


    section {
      z-index: 0;
      position: relative;

      background-color: $white;
      padding: 0 0 10rem 0;

      .h3, h3 {
        &.badge {
          font-size: 15px;
          font-weight: 600;
          word-spacing: 0;
          padding: 0.5em 1.1em;
          background-color: rgba($primary, .4);
          color: $black;
        }
      }

      h2 {
        font: normal normal 500 44px/54px Poppins;
        margin-top: 0.5rem;
        margin-bottom: 1.3rem;
        letter-spacing: -2px;
        word-spacing: 5px;
      }

      p {
        font: normal normal 18px/30px Poppins;
        color: $gray-700;
        padding-right: 1rem;

        &.lead {
          color: $gray-900;
          font-weight: 600;
          margin-bottom: 1.8rem;
        }
      }
    }

    #my_website{
      //background: transparent url(/static/assets/img/Main-Screen.png) no-repeat 140% 50%;
      //background-size: cover;
      padding: 11rem 0;
      margin-bottom: 5.4rem;
      margin-top: 0;
    }

    #greyshaped {
      background: transparent url('/static/assets/img/GreyShape1.svg') no-repeat 50% 50%;
    }
    #suite-audits {
      text-align: center;
      background-color: transparent;

      .strengths_weakness {
        p {
          padding: 0 20rem;
        }
      }

      .card-primary {
        background-color: $primary;

        & > .card-body > p {
          font-size: 22px;
          font-weight: 600;
        }
      }

      .card {
        margin-top: 2rem;
        min-height: 490px;
        box-shadow: 0px 0px 28px rgba(0, 0, 0, .29);
        position: relative;
        text-align: left;

        &-body {
          padding: 1.5rem;
        }

        p.more {
          position: absolute;
          bottom: 10px;
        }

        h4 {
          font-size: 22px;
          margin: 1.5rem 0;
          font-weight: 600;
        }

        p {
          font-size: 18px;
          padding-right:0;

        }
      }
    }

    #metrics {
      //background: $white url('/static/assets/img/happy-person-metrics.png') no-repeat 100% 0%;
      margin-bottom: 7rem;

      .badge {
        margin-top: 7.3rem;
      }

      p {
        //font-weight: 300;

      }
    }

    #beta {
      background: $white url("/static/assets/img/Group-558.svg") no-repeat 50% 50%;
      margin-top: -50px;
      padding: 150px 0;
      margin-bottom: 8rem;

      .badge {
        background-color: $primary;
      }

      h2 {
        color: $white;
        margin-bottom: 2.4rem;
      }

      form {
        margin: 0 15%;

        .form-check {
          color: $white;

          a {
            color: $primary;
            text-decoration: underline;
          }
        }
      }
    }

    #crawl_analyze_report {
      padding-bottom: 4rem;

      .card {
        box-shadow: $box-shadow-lg;

        & + .card {
          margin-top: 9rem;
        }

        &-body {
          //padding: 4rem 2rem;

          //ul {
          //  list-style: none; /* Remove default bullets */
          //  li {
          //    font-size: 18px;
          //  }
          //}

          //ul li::before {
          //  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
          //  color: $primary; /* Change the color */
          //  font-weight: bold; /* If you want it to be bold */
          //  font-size: 24px;
          //  display: inline-block; /* Needed to add space between the bullet and the text */
          //  width: 1em; /* Also needed for space (tweak if needed) */
          //  margin-left: -1em; /* Also needed for space (tweak if needed) */
          //}
        }

        .h3, h3 {
          //position: absolute;
          top: -82px;
          left: -86px;
          //font-size: 19px;
          //padding: 1em;

          span {
            //background: $primary;
            //position: relative;
            //margin-left: -20px;
            //border-radius: 5px;
            //padding: .4rem .4rem .4rem 2rem;
            //font-weight: 600;
            //box-shadow: $box-shadow-lg;
          }

          img {
            height:auto;
          }
        }
      }
    }

    #policies {
      background: $white url('/static/assets/img/GreyShape2.svg') no-repeat 50% 50%;
      padding: 6rem 0 10rem 0;

      .badge {
        margin-top: 7.3rem;
      }
    }

    #compliance {
      padding: 1rem 0 10rem 0;
      text-align: center;
      form {
        margin: 0 15%;
      }

      .smallprint {
        padding-top: 1rem;
        font-size: $font-size-sm;
        color: $gray-600;
      }
    }
  }}


@include media-breakpoint-down(sm) {
  :root {
    --gutter: 10px;
    --gutter-calc: 85% - var(--gutter) * 2;
  }

  .input-group>.form-control {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem;
  }
  .input-group-lg>.input-group-append>.btn {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem;
  }

  .app {
    display: grid;
    align-content: start;
  }

  .app > * {
    grid-column: 2 / -2;
  }

  .app > .full {
    grid-column: 1 / -1;
  }

  .hs {
    display: grid;
    grid-gap: calc(var(--gutter) / 2);
    //grid-template-columns: 10px repeat(4,var(--gutter-calc)) 10px;
    grid-template-columns: 10px repeat(4,calc(85% - var(--gutter)*2)) 10px;
    grid-template-rows: minmax(150px, 1fr);
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    padding: 2rem 0;
  }

  .hs:before,
  .hs:after {
    content: '';
  }

  /* Hide scrollbar */
  .hide-scroll {
    overflow-y: hidden;
    margin-bottom: calc(-.1 * var(--gutter));
  }


  /* Demo styles */


  .app {
    //width: 375px;
    //height: 667px;
    //background: #DBD0BC;
    //overflow-y: scroll;
  }

  .hs > .item,
  .item {
    //height: 450px;
    //scroll-snap-align: center;
    //padding: calc(var(--gutter) / 2 * 1.5);
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //align-items: center;
    //background: #fff;
    //border-radius: 8px;
  }
  form {
    .input-group-lg>.form-control, .input-group-lg>.input-group-append>.btn  {
      font-size:1rem;
    }
  }
  #suite-audits {
    .card-primary {
      background-color: $primary;

      & > .card-body > p {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .card {
      margin-top: .5rem;
      height: 400px;
      box-shadow: 0px 0px 28px rgba(0, 0, 0, .15);
      position: relative;

      &-body {
        padding: 2rem 1rem;
      }

      p.more {
        position: absolute;
        bottom: 10px;
      }

      h4 {
        font-size: 22px;
        margin: .5rem 0;
        font-weight: 600;
      }

      p {
        font-size: 16px;
      }
    }
  }
}


/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 1.25em; height: 1.25em;
  border: 1px solid $gray-400;
  background: $white;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '\2713\0020';
  position: absolute;
  top: -.4rem;
  left: 0rem;
  font-size: 2rem;
  line-height: 0.8;
  color: $primary;
  transition: all .2s;
  font-family: Montserrat, 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: $gray-500;
  background-color: $gray-500;
}
[type="checkbox"]:disabled:checked + label:after {
  color: $black;
}
[type="checkbox"]:disabled + label {
  color: $gray-300;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  border: 1px solid $purple;
}

/* hover style just for information */
label:hover:before {
  border: 1px solid $purple !important;
}
