.pre-grid {
  width: 100%;
  word-wrap: break-word;
  display: grid;
}

pre {
  overflow: auto;
  scrollbar-color: red transparent;
  width: auto;
  max-height: 600px;
  white-space: pre-wrap;
}

pre code {
  white-space: inherit;
  padding: 0;
}

/*

Google Code style (c) Aahan Krish <geekpanth3r@gmail.com>

*/


.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: white;
  color: black;

  table {
    width: 100%;

  }

  & table.hljs-ln {
    box-shadow: none !important;
  }

  & table thead tr th, table tbody tr td {
    padding: .25rem !important;
  }

  & table tbody tr td:last-child {
    text-align: left;
  }

}

.hljs-comment,
.hljs-quote {
  color: #800;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-section,
.hljs-title,
.hljs-name {
  color: #008;
}

.hljs-variable,
.hljs-template-variable {
  color: #660;
}

.hljs-string,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-regexp {
  color: #080;
}

.hljs-literal,
.hljs-symbol,
.hljs-bullet,
.hljs-meta,
.hljs-number,
.hljs-link {
  color: #066;
}

.hljs-title,
.hljs-doctag,
.hljs-type,
.hljs-attr,
.hljs-built_in,
.hljs-builtin-name,
.hljs-params {
  color: #606;
}

.hljs-attribute,
.hljs-subst {
  color: #000;
}

.hljs-formula {
  background-color: #eee;
  font-style: italic;
}

.hljs-selector-id,
.hljs-selector-class {
  color: #9B703F
}

.hljs-addition {
  background-color: #baeeba;
}

.hljs-deletion {
  background-color: #ffc8bd;
}

.hljs-doctag,
.hljs-strong {
  font-weight: bold;
}

.hljs-emphasis {
  font-style: italic;
}
