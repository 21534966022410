@import "bootstrap/scss/mixins";
// Required
@import "variables"; // Required


.severity {
  margin: 0 1px;

  & + .help {
    border-left: 1px solid #DEDEDE;
    margin-left: 1rem;
    padding-left: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  &-success-label,
  &-warning-label,
  &-danger-label {
    color: #76718a;
    text-decoration: none;
    cursor: pointer;
  }

  &-success-label.active,
  &-success-label:active {
    color: $success;
  }

  &-warning-label.active,
  &-warning-label:active {
    color: #f7991e;
  }

  &-danger-label.active,
  &-danger-label:active {
    color: #ff0000;
  }

  &-success,
  &-warning,
  &-danger {
    display: flex;
    align-items: center;
    width: 112px;
    height: 24px;
    padding-left: 35px;
    color: #ffffff;
    font: normal normal 500 14px/18px Montserrat;
    border-radius: 4px;
    opacity: 0.7;
    box-shadow: $box-shadow-lg;

  }

  &-success {
    background: $success 0% 0% no-repeat padding-box;
  }

  &-warning {
    background: #F7991E 0% 0% no-repeat padding-box;
  }

  &-danger {
    background: #ff0000 0% 0% no-repeat padding-box;
  }

  &-icon {
    width: 24px;
    height: 24px;
  }

  span {
    font: normal normal 500 14px/20px Montserrat;
  }

  &-status {
    margin-left: 18px;
    margin-right: 30px;

    img {
      position: absolute;
      z-index: 1;
    }
  }

  .affected-pages {
    font-size: 12px;
  }

  img {
    position: absolute;
    z-index: 1;
  }
}

@media screen and (max-width: 1300px) {
  .severity {
    img {
      width: 1.25rem;
      height: 1.25rem;
    }

    span {
      font: normal normal 500 13px/20px Montserrat;
    }

    &-success,
    &-warning,
    &-danger {
      width: 100px;
      height: 20px;
      font: normal normal 500 10px/18px Montserrat;
    }

    &-icon {
      width: 20px;
      height: 20px;
    }
  }
}
