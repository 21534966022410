@import "bootstrap/scss/mixins";
// Required
@import "variables"; // Required

#dashboard-wrapper .tabs table.impact-result {
  thead th, tbody td {
    &:nth-child(1) {
      padding:15px !important;
    }

    &:nth-child(2) {
      padding:15px !important;
    }
  }
}
table {
  box-shadow: 0px 0px 28px #0000001a !important;
  border-radius: 10px;

  thead {
    background: rgba(5,20,40,0.075) 0% 0% no-repeat padding-box;
    min-height: 40px;
    th {
      //border: none !important;
      vertical-align: middle !important;
    }
  }
}

thead tr th,
tbody tr td {
  font: normal normal 500 14px/18px Montserrat;
  padding: 1rem !important;
  color: black;
}

tbody tr td {
  .break {
    word-break: break-all;
  }
  a {
    color: inherit;
  }
  a:hover {
    color: inherit;
    text-decoration: none;
  }
}


table {
  box-shadow: 0px 0px 28px #0000001a !important;
  border-radius: 10px;

  thead tr th,
  tbody tr td {
    font: normal normal 500 14px/18px Montserrat;
    color: black;
  }

  thead {
    th {



      //border: none !important;
      vertical-align: middle !important;
      //padding: 15px 25px !important;

      span.column-title {
        //margin-right: 10px;
      }

      span.sorting-arrow {
        cursor: pointer;
        margin-left: .25rem;
      }

      img {
        transform: rotate(-180deg);
        transition: all 0.2s;
      }

      img.sorting-arrow-default {
        display: block;
      }

      img.sorting-arrow-up {
        display: none;
      }

      .sorting-arrow.ascending {
        img.sorting-arrow-default {
          display: none;
        }

        img.sorting-arrow-up {
          display: block;
        }
      }

      .sorting-arrow.descending {
        img {
          transform: rotate(0deg);
        }

        img.sorting-arrow-default {
          display: none;
        }

        img.sorting-arrow-up {
          display: block;
        }
      }

      //&:first-child {
      //  width: 50%;
      //}
    }
  }

  tbody {
    tr {
      td {

        //padding: .625rem;
        vertical-align: middle;
        &.impact {
          .progress {
            width: 55px;
            height:17px;
          }

        }

        .check {
          &-success {
            color: $success;
            svg {
              width: 1rem;
              height: 1rem;
              fill: currentColor;
            }
          }
          &-danger {
            color: $danger;
            svg {
              width: 1rem;
              height: 1rem;
              fill: currentColor;
            }
          }
          &-warning {
            color: $warning;
            svg {
              width: 1rem;
              height: 1rem;
              fill: currentColor;
            }
          }
        }

        .btn-request {
          //width: 135px;
          height: 40px;
          color: #431e2e;
          font: normal normal 600 14px/25px Montserrat;
          background: #d9d2d5 0% 0% no-repeat padding-box;
          box-shadow: $box-shadow-lg;
          border-radius: 6px;

          &:hover {
            color: #ffffff;
            fill: #ffffff;
            background: #431e2e 0% 0% no-repeat padding-box;
          }
        }
      }
    }
  }
}


#content-statistics {
  table.table{
    th:not(:first-child) {
      font-size:11px;
    }
  }
}
