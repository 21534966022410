//
// General form controls (plus a few specific high-level interventions)
//
@import "bootstrap/scss/mixins";
// Required
@import "variables"; // Required

#page-audits-performance, #page-audits-core_web_vitals {
  td:last-child {
    text-align: left !important;
    width: 15%;

  }

  tr {
    border-radius: 4px;
    margin: .5rem;
  }

  //border-collapse: separate;
  //tbody {
  //  td {
  //    border: solid 10px transparent;
  //  }
  //
  //  td:first-child {
  //    border-top-left-radius: 10px;
  //    border-bottom-left-radius: 10px;
  //  }
  //
  //  td:last-child {
  //    border-top-right-radius: 10px;
  //    border-bottom-right-radius: 10px;
  //  }
  //}

  .cwv-legend {
    li span {
      color: $black;
    }

    li.success {
      content: "•";
      color: $success;

      svg {
        width: 1rem;
        height: 1rem;
        fill: currentColor;
      }
    }

    li.warning {
      content: "•";
      color: $warning;

      svg {
        width: 1rem;
        height: 1rem;
        fill: currentColor;
      }
    }

    li.danger {
      content: "•";
      color: $danger;

      svg {
        width: 1rem;
        height: 1rem;
        fill: currentColor;
      }
    }
  }

  .severity {
    margin-bottom: 0.25rem;

    &-rating {
      background-color: #ccc;
      display: inline-block;
      border-bottom-right-radius: .25rem;
      border-top-right-radius: .25rem;
      padding: 2px .25rem;
      margin-left: .25rem;
      color: $black;

    }

    &-success, &-warning, &-danger {
      width: auto;
      padding-left: .25rem;
      display: inline-block;
    }
  }

  .progress {
    position: relative;
    border-left: 1px solid #717171;
    border-radius: 0;
    overflow: unset;

    &-current-position {
      position: absolute;
      width: 50%;
      background: transparent;
      border-right: 3px solid #F7981E;
      left: 0;
      bottom: 7px;
      height: 25px;
      padding: 0;
      z-index: 999;
      text-align: right;
      padding-right: .25rem;
      font: normal normal 500 10px/12px Montserrat;
      color: $gray-500;
      &-label{
        width: 50px;
        text-align: left;
        margin-left: 12px;
      }
    }

    &-title {
      font: normal normal 500 10px/12px Montserrat;
      color: $gray-500;
      text-align: center;
      padding-bottom: 2rem;
    }

    &-marker {
      font: normal normal 500 10px/12px Montserrat;
      color: $gray-500;
    }

    &-bar {
      border-right: 1px solid #717171;
      border-top: 6px solid #f5f5f5;
      border-bottom: 6px solid #f5f5f5;
      overflow: unset;
      position: relative;

      span {
        position: absolute;
        /* left: 0px; */
        width: 50px;
        height: 15px;
        padding: 0;
        margin: 0;
        display: block;
        left: -50px;
        top: 11px;
      }
    }
  }
}
