.og {
  cursor: pointer;
  position: relative;
  display: block;
  background-color: #ffffff;
  margin: 0 -20px;
  overflow: hidden;
  font-size: 14px;
  font-family: Helvetica, Arial, system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif !important;
  -webkit-font-smoothing: subpixel-antialiased;

  &-fb {
    margin: 0;
    width: 500px;
    cursor: pointer;
    position: relative;
    display: block;
    background-color: #ffffff;
    margin: 0 -20px;
    overflow: hidden;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;

    &-image {
      border: 1px solid #dadde1;
      width: 500px;
      height: 261px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &-text {
      border: 1px solid #dadde1;
      border-top: none;
      padding: 10px 12px;
      color: #1d2129;
      background: #f2f3f5;

      &-link {
        border-collapse: separate;
        color: #606770;
        direction: ltr;
        display: block;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 12px;
        height: 11px;
        line-height: 11px;
        overflow-x: hidden;
        overflow-y: hidden;
        text-align: left;
        text-overflow: ellipsis;
        text-transform: uppercase;
        user-select: none;
        white-space: nowrap;
        width: 501px;
        word-wrap: break-word;
        -webkit-border-horizontal-spacing: 0px;
        -webkit-border-vertical-spacing: 0px;
        -webkit-font-smoothing: antialiased;
      }

      &-content {
        padding-top:5px;
        border-collapse: separate;
        color: #4b4f56;
        direction: ltr;
        display: block;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 12px;
        height: 46px;
        line-height: 14.4px;
        max-height: 46px;
        overflow-x: hidden;
        overflow-y: hidden;
        text-align: left;
        user-select: none;
        word-wrap: break-word;
        -webkit-border-horizontal-spacing: 0px;
        -webkit-border-vertical-spacing: 0px;
        -webkit-font-smoothing: antialiased;

        &-description {
          border-collapse: separate;
          color: #606770;
          direction: ltr;
          display: -webkit-box;
          font-family: Helvetica, Arial, sans-serif;
          font-size: 14px;
          height: 18px;
          line-height: 20px;
          margin-top: 3px;
          max-height: 80px;
          overflow-x: hidden;
          overflow-y: hidden;
          text-align: left;
          text-overflow: ellipsis;
          user-select: none;
          white-space: normal;
          word-break: break-word;
          word-wrap: break-word;
          -webkit-border-horizontal-spacing: 0px;
          -webkit-border-vertical-spacing: 0px;
          -webkit-box-orient: vertical;
          -webkit-font-smoothing: antialiased;
          -webkit-line-clamp: 1;
        }
        &-title {
          font-size: 16px !important;
          line-height: 20px !important;
          font-weight: 600 !important;
          margin-top: 5px !important;
          border-collapse: separate;
          color: #1d2129;
          cursor: pointer;
          direction: ltr;
          display: inline;
          font-family: Helvetica, Arial, sans-serif;
          height: auto;
          letter-spacing: normal;
          line-height: 18px;
          text-align: left;
          text-decoration-color: #1d2129;
          text-decoration-line: none;
          text-decoration-style: solid;
          transition-delay: 0s;
          transition-duration: 0.1s;
          transition-property: color;
          transition-timing-function: ease-in-out;
          user-select: none;
          white-space: normal;
          width: auto;
          word-wrap: break-word;
          -webkit-border-horizontal-spacing: 0px;
          -webkit-border-vertical-spacing: 0px;
          -webkit-font-smoothing: antialiased;
        }
      }


    }
  }
}



