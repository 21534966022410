@import "bootstrap/scss/mixins";
// Required
@import "variables"; // Required


a.btn {
  svg {
    width: 1rem;
    height: 1rem;
  }
}

#login {
  @keyframes fumes {
    50% {
      transform: scale(1.5);
      opacity: 0;
    }
    51% {
      transform: scale(0.8);
    }
    100% {
      opacity: 0.6;
      transform: scale(1)
    }
  }

  @keyframes bounce {
    0% {
      transform: translate3d(0px, 0px, 0);
    }
    50% {
      transform: translate3d(0px, -4px, 0);
    }
    100% {
      transform: translate3d(0px, 0px, 0);
    }
  }

  @keyframes exhaust {
    0% {
      background: linear-gradient(to bottom, transparent 10%, $white 100%);
    }
    50% {
      background: linear-gradient(to bottom, transparent 8%, $white 100%);
    }
    75% {
      background: linear-gradient(to bottom, transparent 12%, $white 100%);
    }
  }

  @keyframes fumes2 {
    50% {
      transform: scale(1.1);
    }
  }


  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }

  @keyframes launch {
    0% {
      transform: translateY(0);
    }
    10% {
      transform: translateY(-200px);
    }
    100% {
      transform: translateY(-600px);
    }
  }
  @keyframes launchSwoosh {
    0% {
      transform: matrix(1, 0, 0, 1, 0, 0);
    }
    10% {
      opacity: .15;
      transform: matrix(1, 0, 0, 5, 0, -125);
      //background-size: 50% 120%;
    }
    100% {
      opacity: 0;
      transform: matrix(1, 0, 0, 10, 0, -250);
    }
  }


  h6 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px;
  }

  h1 {
    font: normal normal 600 30px/37px Montserrat
  }

  .row.shadow {
    border-radius: 10px;
    border: 1px solid $gray-200;
  }

  .wasp-takeoff h3 {
    color: $white;
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;

  }

  .form-login {
    padding: 1.5em;
  }
  .form-check {
    padding-left: 0;
  //  padding-top:0.25rem;
  //  [type=checkbox], [type=checkbox]:before, [type=checkbox]:after{
  //    box-sizing: content-box;
  //    border: 1px solid #ced4da;
  //    &:focus {
  //      border:1px solid $primary;
  //    }
  //  }
  //  label {
  //    font-size:1rem;
  //    color: $white;
  //  }
  //
  //  a {
  //    color: $secondary;
  //    text-decoration: underline;
  //  }
  }
  .form-group {
    &>label {
      display:none;
    }
  }

  .wasp-takeoff {
    background: $primary;
    padding: 2em 0 1em 0;
  }

  .btn-social {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DEDEDE;
    border-radius: 8px;
    opacity: 1;
    font: normal normal 600 16px/25px Poppins;
    &:hover {
      box-shadow: $box-shadow-lg;
    }
  }


  @include media-breakpoint-up(sm) {
    padding-top: 7%;


    .wasp-takeoff {
      #swoosh {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        background: transparent url('/static/assets/img/swoosh.svg') no-repeat center bottom;
        background-size: 60%;

        &.launchSwoosh {
          animation: launchSwoosh 1s 0.3s 1 linear;
        }

      }

      #wasp {
        padding-top: 30px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        background: transparent url('/static/assets/img/Wasp-Front.png') no-repeat center center;

        background-size: 60%;

        &.launch {
          animation: launch 1s 0.3s 1 ease-out !important;
        }

        &.bounce {
          animation: bounce 0.5s infinite;
        }

        &.shake {
          animation: shake 1s infinite
        }


      }

      #clouds {
        overflow: hidden;
        border-radius: 10px 0 0 10px;

        .go li {
          width: 60px;
          height: 60px;
          background-color: rgba(255, 255, 255, 0);
          list-style: none;
          position: absolute;
          border-radius: 100%;

          &:nth-child(5) {
            background-color: rgba(255, 255, 255, 0.81);
            width: 130px;
            height: 130px;
            left: -45px;
            bottom: -56px;
          }

          &:nth-child(6) {
            background-color: rgba(255, 255, 255, .25);
            width: 150px;
            height: 150px;
            left: -55px;
            bottom: -66px;
          }

          &:nth-child(7) {
            background-color: rgba(255, 255, 255, 0.75);
            width: 130px;
            height: 130px;
            left: 63px;
            bottom: -90px;
          }

          &:nth-child(8) {
            background-color: rgba(255, 255, 255, .25);
            width: 150px;
            height: 150px;
            left: 53px;
            bottom: -100px;
          }

          &:nth-child(9) {
            background-color: rgba(255, 255, 255, 0.75);
            width: 165px;
            height: 165px;
            left: 185px;
            bottom: -85px;
          }

          &:nth-child(10) {
            background-color: rgba(255, 255, 255, .25);
            width: 185px;
            height: 185px;
            left: 175px;
            bottom: -95px;
          }

          &:nth-child(11) {
            background-color: rgba(255, 255, 255, 0.8);
            width: 80px;
            height: 80px;
            left: 350px;
            bottom: -40px;
          }

          &:nth-child(12) {
            background-color: rgba(255, 255, 255, .25);
            width: 100px;
            height: 100px;
            left: 340px;
            bottom: -50px;
          }

        }

        .exhaust-fumes li {
          width: 60px;
          height: 60px;
          background-color: rgba(255, 255, 255, .5);
          list-style: none;
          position: absolute;
          border-radius: 100%;

          &:first-child {
            width: 200px;
            height: 200px;
            bottom: -100px;
            left: 140px;
            animation: fumes 2s infinite;
          }

          &:nth-child(2) {
            background-color: rgba(255, 255, 255, .25);
            width: 220px;
            height: 220px;
            bottom: -100px;
            left: 130px;
            animation: fumes 2s infinite;
          }

          &:nth-child(3) {
            width: 120px;
            height: 120px;
            left: 70px;
            bottom: -10px;
            animation: fumes 2.5s .5s infinite;
          }

          &:nth-child(4) {
            background-color: rgba(255, 255, 255, .25);
            width: 140px;
            height: 140px;
            left: 60px;
            bottom: -10px;
            animation: fumes 2.5s .5s infinite;
          }
        }

        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
      }

      height: 600px;
      border-radius: 10px 0 0 10px;
      //min-width:300px;
      //max-width:450px;
    }
    .form-login {
      padding: 4rem 1.5rem 0 1.5rem;
      border-radius: 0 10px 10px 0;
      //min-width:300px;
      //max-width:450px;
      background:white;
    }
  }

  @media only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {

    .image {
      background: url('/static/assets/img/wasp-cloud@2x.png') no-repeat;
    }

    .wasp-takeoff .row div {
      padding: 1em;
    }
  }
}

