@import "bootstrap/scss/mixins";
@import "variables"; // Required


.help {
  a {
    cursor: help;
  }
}
.page-audit {
  #page-content-wrapper {
    .detail-row {
      margin: 0.75rem 1.25rem;
    }
    .detail-title {
      margin: 0;
    }
    .detail-box {
      margin: 0.75rem 0;
      min-height: 6.25rem;

      &.ratio {
        width: 7.75rem;
        font: normal normal 600 33px/40px Montserrat;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &.color {
        width: 8.5rem;
        display: -ms-flexbox;
        display: flex;
        align-items: flex-end;
        justify-content: left;
        position:relative;
        box-shadow: 0px 4px 20px #0000001A;
        div {
          font: normal normal 600 14px/18px Montserrat;
          color: white;
          text-shadow: 1px 1px 2px $gray-400;

          position: absolute;
          top:0;
          bottom:0;
          left:0;
          right:0;
          display: -ms-flexbox;
          display: flex;
          justify-content: left;
          align-items: flex-end;
          padding:0.5rem;
          border-radius: 0.25rem;
          margin: 0.125rem;

        }
      }

    }
  }
}

.image_size_responsive {
  .ratio {
    font: normal normal 600 16px/22px Montserrat !important;
  }
}
