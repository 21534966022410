//
// General form controls (plus a few specific high-level interventions)
//
@import "bootstrap/scss/mixins";
// Required
@import "variables"; // Required

.form-control-lg {
  @include box-shadow($box-shadow-lg);
}

.form-control-lg:focus {
  background: #F5F5F5 0 0 no-repeat padding-box;
  border: 1px solid #F7991E;
  @include box-shadow($box-shadow-lg);
}

//#search{
//  padding:0 20px;
//  border-bottom: 1px solid rgba(5,20,40,0.125);
//
//  .search-box {
//    border-left: 1px solid rgba(5,20,40,0.125);
//    padding: 6px 0 6px 20px;
//  }
//  #search-urls {
//    border: none;
//    box-shadow: none;
//    background-image: url(/static/assets/img/sidemenu-seo-activeIcon.svg);
//    background-repeat: no-repeat;
//    background-position: 95% 50%;
//  }
//}


