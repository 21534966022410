//
// General form controls (plus a few specific high-level interventions)
//
@import "bootstrap/scss/mixins";
// Required
@import "variables"; // Required

.page-heading {
  font: normal normal 600 18px/22px Montserrat;
  letter-spacing: 0px;
  color: $black;
}

.table-heading, td h3.table-heading {
  font: normal normal 600 14px/18px Montserrat;

}

td {
  p:last-child {
    margin-bottom:0;
  }
  h3 {
    font: normal normal 600 16px/22px Montserrat;
    margin-top: 0;

    small {
      font: normal normal 500 14px/18px Montserrat;
      color: #76718A;
    }
  }
}

em.disabled {
  color: $gray-700;
}

.external {
  margin: 0 0 3px 2px;
}

.badge {
  &-outline {
    border: 1px solid #DEDEDE;
    border-radius: 4px;
    font: normal normal 600 12px/.75rem Montserrat;
    margin-bottom: 2px;
  }
}

.btn {
  font-weight:500;
}

a.link {
  color: $info;
}
