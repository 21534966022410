@import "bootstrap/scss/mixins";
// Required
@import "variables"; // Required


.report-card {
  cursor: pointer;
  //min-width: 200px;
}
.card {
  box-shadow: $box-shadow-lg;
  .table {
    margin-bottom:0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &-example {
    border:1px solid $info;
    margin-top: $card-group-margin * 2 ;
    margin-bottom: $card-group-margin * 2 ;
  }
  &-report {
    .detail-box {
      &:first-child{
        border-left:none;
      }
      border-left: 1px solid $gray-300;
      padding: 0 .75rem;
      margin: 0.75rem;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;

      &:last-child{
        background-color: $secondary;
        padding: 1.5rem;;
        margin: 0;
        color: $white;
        min-width:160px;
      };
    }
    &-title{
      margin-bottom:0;
      font: normal normal 600 14px/19px Montserrat;
    }
    &-metric{
      margin-bottom:0;
      font: normal normal 600 22px/29px Montserrat;
    }

  }
}

.card + .card {
  margin-top: $card-img-overlay-padding;
}

#intro-box {
  .card {
    height: 250px;
  }
}


