.filmstrip {

  img {
    margin-left: 1rem;
    width: 75%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #0000000D;
    border: 1px solid #D1D1D1;
  }

  div.col > div {
    margin-top: 1rem;
    margin-left: 1rem;
    font: normal normal 500 14px/18px Montserrat;
    letter-spacing: 0px;
    color: #000000;

    span {
      font-weight: 400;
    }
  }

}

